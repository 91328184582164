'use client'
import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube, FaApple, FaSpotify } from 'react-icons/fa';
import { motion } from 'framer-motion';

const Footer: React.FC = () => {
  const socialLinks = [
    { icon: FaInstagram, url: 'https://www.instagram.com/johnny.danz.music' },
    { icon: FaFacebook, url: 'https://m.facebook.com/john.danziger.96?mibextid=LQQJ4d' },
    { icon: FaYoutube, url: 'https://www.youtube.com/channel/UCimPY4pjI-pAQkZsITQkiaA' },
    { icon: FaSpotify, url: 'https://open.spotify.com/artist/3ADLTzLmpqenRKYdktnkwQ?si=C_g2uUAiQKGqqczNT8CrDw&nd=1&dlsi=e6973016c39a4009' },
    { icon: FaApple, url: 'https://music.apple.com/us/artist/john-danziger/1725918985' },
  ];

  return (
    <motion.footer 
      className="font-goodfont py-6 sm:py-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      <div className="container mx-auto px-4">
        <motion.hr 
          className="border-gray-800 my-6 sm:my-8"
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 0.8 }}
        />
        <motion.div 
          className="flex justify-center items-center space-x-4 sm:space-x-6 mb-4"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {socialLinks.map((link, index) => (
            <motion.a
              key={index}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-white transition-colors duration-300"
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 0.9 }}
            >
              <link.icon size={20} className="sm:w-6 sm:h-6" />
            </motion.a>
          ))}
        </motion.div>
        <motion.div 
          className="text-center"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <p className="text-base sm:text-lg text-gray-300">
            © {new Date().getFullYear()} John Danziger. All rights reserved.
          </p>
        </motion.div>
      </div>
    </motion.footer>
  );
};

export default Footer;