'use client'
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { motion, AnimatePresence } from 'framer-motion';
import { usePathname } from 'next/navigation';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    if (pathname === '/') {
      const timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 2750);
      return () => clearTimeout(timer);
    } else {
      setShouldAnimate(true);
    }
  }, [pathname]);

  const navItems = [
    { href: '/about', label: 'About' },
    { href: '/lessons', label: 'Lessons' }, 
    { href: '/music', label: 'Music' },
    { href: '/news', label: 'News' },
    { href: '/contact', label: 'Contact' },
  ];

  return (
    <motion.header 
      className="py-4 sm:py-6 border-b border-white font-goodfont"
      initial={{ y: -100 }}
      animate={shouldAnimate ? { y: 0 } : { y: -100 }}
      transition={{ duration: 0.5 }}
    >
      <div className="container mx-auto px-4 flex justify-between items-center">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={shouldAnimate ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <Link href="/" className="text-2xl sm:text-4xl text-white uppercase tracking-widest">
            John Danziger
          </Link>
        </motion.div>
        <nav className="hidden md:block">
          <ul className="flex space-x-4 sm:space-x-8 text-base sm:text-xl text-white">
            {navItems.map((item, index) => (
              <motion.li 
                key={item.href}
                initial={{ opacity: 0, y: -20 }}
                animate={shouldAnimate ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
                transition={{ duration: 0.5, delay: 0.1 * (index + 1) }}
              >
                <Link
                  href={item.href}
                  className="hover:text-gray-300 transition-colors duration-300"
                >
                  {item.label}
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>
        <motion.button
          className="md:hidden text-white focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
          initial={{ opacity: 0, x: 20 }}
          animate={shouldAnimate ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <svg
            className="h-6 w-6 fill-current"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {isOpen ? (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.293 4.293a1 1 0 011.414 0l1 1a1 1 0 010 1.414L13.414 15l8.293 8.293a1 1 0 010 1.414l-1 1a1 1 0 01-1.414 0L12 17.414l-7.293 7.293a1 1 0 01-1.414 0l-1-1a1 1 0 010-1.414L9.586 15 2.293 6.707a1 1 0 010-1.414l1-1a1 1 0 011.414 0L12 11.586l7.293-7.293z"
              />
            ) : (
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm0 5a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zm1 4a1 1 0 100 2h14a1 1 0 100-2H5z"
              />
            )}
          </svg>
        </motion.button>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.nav 
            className="md:hidden mt-4"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <ul className="flex flex-col space-y-2 text-base sm:text-xl text-white px-4">
              {navItems.map((item, index) => (
                <motion.li 
                  key={item.href}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: 0.1 * index }}
                >
                  <Link
                    href={item.href}
                    className="block py-2 hover:text-gray-300 transition-colors duration-300"
                    onClick={() => setIsOpen(false)}
                  >
                    {item.label}
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.nav>
        )}
      </AnimatePresence>
    </motion.header>
  );
};

export default Header;